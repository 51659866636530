<template>
  <b-card-actions
      ref="first-card"
      class="list-card"
      @refresh="refreshStop('first-card')"
  >
    <b-row class="mt-50 mb-5">
      <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
        <b-col cols="12">
          <h4 class="text-white d-inline-block card-title">Menü / Ürün Tanımlama</h4>
        </b-col>
      </div>
    </b-row>
    <b-row>
      <b-col cols="12" class="justify-content-between d-flex">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.add-grup
            variant="primary"
        >
          Kategori Ekle
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.menu-save
            v-b-modal="menuGrupListesi ? 'menu-save':''"
            v-b-tooltip="!menuGrupListesi ? 'Önce Kategori Eklemelisiniz':''"
            variant="success"
            :style="{cursor:menuGrupListesi ? 'pointer':'default'}"
        >
          Ürün Ekle
        </b-button>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mt-1">
      <b-col lg="3" md="4" sm="12" cols="12">
        <b-list-group>
          <b-list-group-item
              v-for="item in menuGrupListesi"
              :key="item.stokGrupID"
              class="menu-grup-list"
              :style="{backgroundColor:selectedGrupID === item.stokGrupID ? '#3AA6B9':'transparent'}"
              button
              @click="selectedGrupID = item.stokGrupID"
          >
            <h5 class="d-inline-block mb-0" :style="{color:selectedGrupID === item.stokGrupID ? 'white':'black'}">
              {{
                item.grupAdi
              }}</h5>
            <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-dark"
                no-caret
            >
              <template #button-content>
                <font-awesome-icon icon="ellipsis-vertical"/>
              </template>
              <b-dropdown-item v-b-modal.grup-duzenle variant="warning"
                               @click="updateGrup.grupID = item.stokGrupID,updateGrup.grupName = item.grupAdi"
              >
                <font-awesome-icon icon="pen" class="mr-50"/>
                Düzenle
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click="removeGrup(item.stokGrupID)">
                <font-awesome-icon icon="trash" class="mr-50"/>
                Sil
              </b-dropdown-item>
            </b-dropdown>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col lg="9" md="8" sm="12" cols="12">
        <b-row class="mt-lg-0 mt-sm-1 mt-1">
          <b-card v-for="item in menuListesi" :key="item.stokID" v-b-modal="'menu-update'" class="menu-card"
                  @click="updateMenuAtama(item)"
          >
            <h4 class="mb-0 font-weight-bolder text-center">{{ item.stokAdi }}</h4>
            <div class="seperated"/>
            <h5 class="mb-0 font-weight-bolder text-center">{{ item.tutar }}
              <font-awesome-icon icon="turkish-lira"/>
            </h5>
            <h6 class="mb-0 text-center">{{ item.adet }} adet</h6>
          </b-card>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
        id="grup-duzenle"
        centered
        title="Kategori Düzenle"
        no-fade
        ok-title="Düzenle"
        cancel-title="Vazgeç"
        @ok="grupUpdate()"
        @hide="updateGrup.grupName = null,updateGrup.grupID = null"
    >
      <b-form-group
          label="Kategori Adı"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="updateGrup.grupName"
            placeholder="Kategori Adı"
            autocomplete="off"
            @keyup.enter="grupUpdate()"
        />
      </b-form-group>
    </b-modal>
    <b-modal
        id="menu-save"
        centered
        no-fade
        title="Menü Kayıt"
        size="lg"
        hide-footer
    >
      <b-row>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group label="Menü Grubu" label-for="basicInput">
            <model-list-select
                v-model="addMenu.grup"
                :list="menuGrupListesi"
                option-value="stokGrupID"
                option-text="grupAdi"
                placeholder="Menü Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Ürün Adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="addMenu.name"
                placeholder="Ürün Adı"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col lg="4" md="6" sm="12" cols="12">-->
        <!--          <b-form-group-->
        <!--              label="Ürün Resmi"-->
        <!--              label-for="basicInput"-->
        <!--          >-->
        <!--            <b-form-file-->
        <!--                v-model="addMenu.resim"-->
        <!--                placeholder="Resim Seçiniz"-->
        <!--                drop-placeholder="Dosyayı Buraya Bırakınız"-->
        <!--                :file-name-formatter="formatNames"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Ürün Fiyatı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="addMenu.tutar"
                v-numericOnly
                placeholder="Ürünün Fiyatı"
                autocomplete="off"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Ürün KDV (%)"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="addMenu.kdv"
                type="number"
                placeholder="KDV"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Ürün Kodu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="addMenu.urunKodu"
                placeholder="Ürünün kodu"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Ürün Adeti"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="addMenu.adet"
                type="number"
                placeholder="Ürün Adeti"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Stoksuz Satış"
              label-for="basicInput"
          >
            <b-form-checkbox
                v-model="addMenu.stoksuzSatis"
                checked="false"
                class="custom-control-success"
                name="check-button"
                switch
            >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon"/>
                </span>
              <span class="switch-icon-right">
                  <feather-icon icon="XIcon"/>
                </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="12" cols="12" sm="12" class="d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="saveMenuReset()"
          >
            Vazgeç
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="!addMenu.name || !addMenu.grup"
              @click="saveMenu()"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="add-grup"
        centered
        no-fade
        title="Kategori Kayıt"
        hide-footer
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Kategori Adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="addGrup.name"
                placeholder="Kategori Adı"
                autocomplete="off"
                @keyup.enter="saveGrup()"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" cols="12" sm="12" class="d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="addGrup.name = null"
          >
            Vazgeç
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="!addGrup.name"
              @click="saveGrup()"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="menu-update"
        centered
        no-fade
        title="Menü Düzenle"
        size="lg"
        hide-footer
    >
      <b-row>
        <b-col lg="4" cols="6" sm="6">
          <b-form-group label="Menü Grubu" label-for="basicInput">
            <model-list-select
                v-model="updateMenu.grup"
                class=""
                :list="menuGrupListesi"
                option-value="stokGrupID"
                option-text="grupAdi"
                placeholder="Menü Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" cols="6" sm="6">
          <b-form-group
              label="Ürün Adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="updateMenu.name"
                placeholder="Ürün Adı"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col lg="4" cols="6" sm="6">-->
        <!--          <b-form-group-->
        <!--              label="Ürün Resmi"-->
        <!--              label-for="basicInput"-->
        <!--          >-->
        <!--            <b-form-file-->
        <!--                v-model="updateMenu.resim"-->
        <!--                placeholder="Resim Seçiniz"-->
        <!--                drop-placeholder="Dosyayı Buraya Bırakınız"-->
        <!--                :file-name-formatter="formatNames"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col lg="4" cols="6" sm="6">
          <b-form-group
              label="Ürün Fiyatı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="updateMenu.tutar"
                v-numericOnly
                placeholder="Ürünün Fiyatı"
                autocomplete="off"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" cols="6" sm="6">
          <b-form-group
              label="Ürün KDV (%)"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="updateMenu.kdv"
                type="number"
                placeholder="KDV"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" cols="6" sm="6">
          <b-form-group
              label="Ürün Kodu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="updateMenu.urunKodu"
                placeholder="Ürünün kodu"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Ürün Adeti"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="updateMenu.adet"
                type="number"
                placeholder="Ürün Adeti"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12" cols="12">
          <b-form-group
              label="Stoksuz Satış"
              label-for="basicInput"
          >
            <b-form-checkbox
                v-model="updateMenu.stoksuzSatis"
                checked="false"
                class="custom-control-success"
                name="check-button"
                switch
            >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon"/>
                </span>
              <span class="switch-icon-right">
                  <feather-icon icon="XIcon"/>
                </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="12" cols="12" sm="12" class="d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mr-50"
              @click="removeMenu(updateMenu.stokID)"
          >
            Ürünü Sil
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="updateMenuReset()"
          >
            Vazgeç
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="!updateMenu.name || !updateMenu.grup"
              @click="updateMenuPost()"
          >
            Güncelle
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-card-actions>
</template>

<script>
import {
  BFormFile,
  VBModal,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
  BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import axios from 'axios'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    ModelListSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedGrupID: null,
      addMenu: {
        stoksuzSatis: false,
        adet: 0,
        grup: null,
        name: null,
        tutar: null,
        kdv: null,
        urunKodu: null,
        resim: null,
      },
      updateMenu: {
        stokID: null,
        grup: null,
        name: null,
        tutar: null,
        kdv: null,
        urunKodu: null,
        resim: null,
      },
      addGrup: {
        name: null,
      },
      updateGrup: {
        grupName: null,
        grupID: null,
      },
      maskOptions: {
        customDelimiter: {
          delimiter: '%',
          blocks: [2, 0],
        },
      },
    }
  },
  computed: {
    menuListesi: () => store.getters.GET_MENU_LIST,
    menuGrupListesi: () => store.getters.GET_MENU_GRUP_LIST,
  },
  watch: {
    selectedGrupID(newVal) {
      this.getGrupMenu(newVal)
    },
  },
  created() {
    this.salonListPost()
  },
  methods: {
    updateMenuAtama(item) {
      const menu = this.updateMenu
      menu.stokID = item.stokID
      menu.grup = this.menuGrupListesi.find(x => x.stokGrupID === item.stokGrupID)
      menu.name = item.stokAdi
      menu.tutar = item.tutar
      menu.urunKodu = item.urunKodu
      menu.kdv = item.kdv
      menu.adet = item.adet
      menu.stoksuzSatis = item.stoksuzSatis === '1'
    },
    removeMenu(stokID) {
      this.$swal({
        title: 'Eminmisiniz?',
        text: 'Bu işlem geri alınamaz',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil!',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const fd = this.postSchema()
              fd.append('methodName', 'stokSil')
              fd.append('stokID', stokID)
              axios.post('', fd)
                  .then(response => {
                    if (response.result.status === 200) {
                      this.getGrupMenu()
                      this.$bvModal.hide('menu-update')
                    }
                  })
            }
          })
    },
    saveGrup() {
      const fd = this.postSchema()
      fd.append('methodName', 'stokGrupKayit')
      fd.append('stokGrupAdi', this.addGrup.name)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.addGrup.name = null
              this.$bvModal.hide('add-grup')
              this.salonListPost()
            }
          })
    },
    updateMenuPost() {
      const item = this.updateMenu
      const fd = this.postSchema()
      fd.append('methodName', 'stokDuzenle')
      fd.append('stokID', item.stokID)
      fd.append('resim', item.resim || '')
      fd.append('updateList[stokAdi]', item.name || '')
      fd.append('updateList[stokGrupID]', item.grup.stokGrupID || item.grup)
      fd.append('updateList[tutar]', item.tutar || 0)
      fd.append('updateList[kdv]', item.kdv || 0)
      fd.append('updateList[urunKodu]', item.urunKodu || '')
      fd.append('updateList[adet]', item.adet || 1)
      fd.append('updateList[stoksuzSatis]', item.stoksuzSatis === true ? 1 : 0)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('menu-update')
              this.saveMenuReset()
              this.getGrupMenu(item.grup.stokGrupID)
            }
          })
    },
    saveMenu() {
      const item = this.addMenu
      const fd = this.postSchema()
      fd.append('methodName', 'stokKayit')
      fd.append('resim', item.resim || '')
      fd.append('saveData[stokAdi]', item.name || ' ')
      fd.append('saveData[stokGrupID]', item.grup.stokGrupID || item.grup)
      fd.append('saveData[tutar]', item.tutar || 0)
      fd.append('saveData[kdv]', item.kdv || 0)
      fd.append('saveData[urunKodu]', item.urunKodu || '')
      fd.append('saveData[adet]', item.adet || 0)
      fd.append('saveData[stoksuzSatis]', item.stoksuzSatis === true ? 1 : 0)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('menu-save')
              this.saveMenuReset()
              this.getGrupMenu(item.grup.stokGrupID)
            }
          })
    },
    grupUpdate() {
      const fd = this.postSchema()
      fd.append('methodName', 'stokGrupDuzenle')
      fd.append('stokGrupID', this.updateGrup.grupID)
      fd.append('stokGrupAdi', this.updateGrup.grupName)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('grup-duzenle')
              this.updateGrup.grupName = null
              this.updateGrup.grupID = null
              store.commit({
                type: 'SET_MENU_GRUP_LIST',
                action: 1,
                list: response.stokGrupList,
              })
            }
          })
    },
    getGrupMenu(grupID) {
      const fd = this.postSchema()
      fd.append('methodName', 'stokListesi')
      fd.append('stokGrupID', grupID || this.selectedGrupID)
      axios.post('', fd)
          .then(response => (store.commit({
            type: 'SET_MENU_LIST',
            action: 1,
            list: response.stokList,
          })), this.addMenu.grup = this.menuGrupListesi.find(x => x.stokGrupID === grupID || this.selectedGrupID))
    },
    removeGrup(grupID) {
      const fd = this.postSchema()
      fd.append('methodName', 'stokGrupSil')
      fd.append('stokGrupID', grupID)
      axios.post('', fd)
          .then(response => (response.result.status === 200 ? (store.commit({
            type: 'SET_MENU_GRUP_LIST',
            action: 1,
            list: response.stokGrupList,
          }), this.menuGrupListesi ? this.selectedGrupID = this.menuGrupListesi[0].stokGrupID : '') : ''))
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'stok')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    updateMenuReset() {
      this.$bvModal.hide('menu-update')
      this.updateMenu.name = null
      this.updateMenu.tutar = null
      this.updateMenu.kdv = null
      this.updateMenu.resim = null
      this.updateMenu.urunKodu = null
    },
    saveMenuReset() {
      this.$bvModal.hide('menu-save')
      this.addMenu.name = null
      this.addMenu.tutar = null
      this.addMenu.kdv = null
      this.addMenu.resim = null
      this.addMenu.urunKodu = null
      this.addMenu.stoksuzSatis = false
      this.addMenu.adet = 0
    },
    salonListPost() {
      const fd = this.postSchema()
      fd.append('methodName', 'stokGrupListesi')
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_MENU_GRUP_LIST',
              action: 1,
              list: response.stokGrupList,
            })
            this.menuGrupListesi ? this.selectedGrupID = this.menuGrupListesi[0].stokGrupID : ''
          })
    },
  },
}
</script>
<style scoped>
.menu-card {
  cursor: pointer;
  display: inline-block;
  background-color: #ddf2ff;
  min-height: 120px;
  width: 150px;
  margin-right: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px !important;
  border-radius: 3px;
}

.menu-grup-list {
  justify-content: space-between;
  display: flex;
  place-items: center;
}

.list-group-item {
  padding: 0.35rem 1.25rem;
}

.menu-card .card-body {
  padding: 0.5rem;
  display: grid;
  height: 100%;
}

.seperated {
  border: 1px solid #6ECCAF;
  background-color: #6ECCAF;
  height: 2px;
}

</style>
